import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import MainContent from './components/MainContent'
import FeaturesSection from './components/FeaturesSection'
import Testimonials from './components/Testimonials'
import Footer from './components/Footer'
import LaizaImagePage from './components/LaizaImagePage'
import Seller from './components/Seller'

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/seller" element={<Seller />} />
          <Route
            path="/"
            element={
              <>
                <MainContent />
                <FeaturesSection />
                <LaizaImagePage />
                <Testimonials />
              </>
            }
          />{' '}
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
