import React from 'react'
import { Box, Typography, Grid, Button } from '@mui/material'
import laizaimg from '../assets/Frameicon.svg'
import './style.css'
import JoinUsForm from './JoinUsForm'
// import appStoreImg from '../assets/appStore.png'
// import playStoreImg from '../assets/playStore.png'
const MainContent = () => (
  <Box className="main-content">
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{ mt: 1, maxWidth: '850px', mx: 'auto' }}
    >
      <Typography variant="h2" className="future-ecommerce-title">
        The Future of Live E-Commerce
      </Typography>
      <Typography
        variant="body1"
        className="future-ecommerce-description"
        sx={{ maxWidth: '100%', marginBottom: '10px' }}
      >
        Shop, discover, and engage in real-time with your favorite brands and influencers across all
        categories
      </Typography>
      {/* <Box display="flex" justifyContent="center" mt={2} mb={4}>
        <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
          <img src={appStoreImg} alt="Download on the App Store" className="store-button" />
        </a>
        <a
          href="https://play.google.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: '10px' }}
        >
          <img src={playStoreImg} alt="Get it on Google Play" className="store-button" />
        </a>
      </Box> */}
      <JoinUsForm />
    </Box>
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={12}>
        <img src={laizaimg} alt="App Screens" loading="lazy" style={{ width: '100%' }} />
      </Grid>
    </Grid>
  </Box>
)

export default MainContent
