import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  MenuItem,
  Select,
  TextField,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from '@mui/material'
import axios from 'axios'
import './style.css'
import imageSrc from '../assets/FrameRight.png'
import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
  customClass: {
    container: 'swal-toast-container',
    popup: 'swal-toast-popup',
  },
})
const SellerForm = ({
  handleClose,
  handleSubmit,
  formData,
  handleInputChange,
  countries,
  states,
  cities,
}) => {
  return (
    <>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#696969 !important',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#696969 !important',
          },
          overflowY: 'auto',
        }}
      >
        <TextField
          label="Full Name"
          fullWidth
          margin="normal"
          className="customTextField"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
        />
        <TextField
          label="Brand Name"
          fullWidth
          margin="normal"
          className="customTextField"
          name="brandName"
          value={formData.brandName}
          onChange={handleInputChange}
        />
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          className="customTextField"
          name="instagramUsername"
          value={formData.instagramUsername}
          onChange={handleInputChange}
        />
        <TextField
          label="Company Name"
          fullWidth
          margin="normal"
          className="customTextField"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
        />
        <TextField
          label="Email ID"
          fullWidth
          margin="normal"
          className="customTextField"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <TextField
          label="Phone No."
          fullWidth
          margin="normal"
          className="customTextField"
          name="phoneNo"
          value={formData.phoneNo}
          onChange={handleInputChange}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9+]/g, '')
          }}
        />

        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="country" value={formData.country} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select country</span>
            </MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="state" value={formData.state} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select state</span>
            </MenuItem>
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="city" value={formData.city} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select city</span>
            </MenuItem>
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Product Category"
          fullWidth
          margin="normal"
          className="customTextField"
          name="productCategory"
          value={formData.productCategory}
          onChange={handleInputChange}
        />
        <TextField
          label="Followers on Instagram"
          fullWidth
          margin="normal"
          className="customTextField"
          name="followers"
          value={formData.followers}
          onChange={handleInputChange}
        />
        {/* <TextField
          label="Instagram Profile Link"
          fullWidth
          margin="normal"
          className="customTextField"
          name="instagramProfileLink"
          value={formData.instagramProfileLink}
          onChange={handleInputChange}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" className="customCancelButton">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} className="customJoinButton">
          Join Now
        </Button>
      </DialogActions>
    </>
  )
}

const InfluencerForm = ({
  handleClose,
  handleSubmit,
  formData,
  handleInputChange,
  countries,
  states,
  cities,
}) => {
  return (
    <>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#696969 !important',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#696969 !important',
          },
          overflowY: 'auto',
        }}
      >
        <TextField
          label="Full Name"
          fullWidth
          margin="normal"
          className="customTextField"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
        />
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          className="customTextField"
          name="instagramUsername"
          value={formData.instagramUsername}
          onChange={handleInputChange}
        />
        <TextField
          label="Email ID"
          fullWidth
          margin="normal"
          className="customTextField"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <TextField
          label="Phone No."
          fullWidth
          margin="normal"
          className="customTextField"
          name="phoneNo"
          value={formData.phoneNo}
          onChange={handleInputChange}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9+]/g, '')
          }}
        />

        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="country" value={formData.country} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select country</span>
            </MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="state" value={formData.state} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select state</span>
            </MenuItem>
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="city" value={formData.city} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select city</span>
            </MenuItem>
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Product Category"
          fullWidth
          margin="normal"
          className="customTextField"
          name="productCategory"
          value={formData.productCategory}
          onChange={handleInputChange}
        />
        <TextField
          label="Followers on Instagram"
          fullWidth
          margin="normal"
          className="customTextField"
          name="followers"
          value={formData.followers}
          onChange={handleInputChange}
        />
        {/* <TextField
          label="Instagram Profile Link"
          fullWidth
          margin="normal"
          className="customTextField"
          name="instagramProfileLink"
          value={formData.instagramProfileLink}
          onChange={handleInputChange}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" className="customCancelButton">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} className="customJoinButton">
          Join Now
        </Button>
      </DialogActions>
    </>
  )
}

const JoinUsForm = () => {
  const [role, setRole] = useState('')
  const [openForm, setOpenForm] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [formData, setFormData] = useState({
    fullName: '',
    brandName: '',
    companyName: '',
    email: '',
    phoneNo: '',
    city: '',
    state: '',
    country: '',
    productCategory: '',
    followers: '',
    // instagramProfileLink: '',
    instagramUsername: '',
  })

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://laiza.encantotech.in/api/get_countries')
      setCountries(response.data.data)
    } catch (error) {
      console.log(error)
      // Toast.fire({
      //   icon: 'error',
      //   title: `Error fetching countries: ${error.response?.data?.message || error.message}`,
      // })
    }
  }

  const fetchStates = async () => {
    if (formData.country) {
      try {
        const response = await axios.get(
          `https://laiza.encantotech.in/api/get_states/${formData.country}`,
        )
        setStates(response.data.data)
      } catch (error) {
        console.log(error)
        // Toast.fire({
        //   icon: 'error',
        //   title: `Error fetching states: ${error.response?.data?.message || error.message}`,
        // })
      }
    }
  }

  const fetchCities = async () => {
    if (formData.state) {
      try {
        const response = await axios.get(
          `https://laiza.encantotech.in/api/get_cities/${formData.state}`,
        )
        setCities(response.data.data)
      } catch (error) {
        console.log(error)
        // Toast.fire({
        //   icon: 'error',
        //   title: `Error fetching cities: ${error.response?.data?.message || error.message}`,
        // })
      }
    }
  }

  useEffect(() => {
    fetchStates()
  }, [formData.country])

  useEffect(() => {
    fetchCities()
  }, [formData.state])

  useEffect(() => {
    if (openForm) {
      fetchCountries()
      setStates([])
      setCities([])
    }
  }, [openForm])

  const handleRoleChange = (role) => {
    setRole(role)
    setOpenForm(true)
  }
  const handleCloseForm = () => {
    setOpenForm(false)
    setRole('')
    setFormData({
      fullName: '',
      brandName: '',
      companyName: '',
      email: '',
      phoneNo: '',
      city: '',
      state: '',
      country: '',
      productCategory: '',
      followers: '',
      // instagramProfileLink: '',
      instagramUsername: '',
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleFormSubmit = async () => {
    const userType = role === 'seller' ? 'seller' : 'influencer'

    const payload = {
      name: formData.fullName || null,
      phone_number: formData.phoneNo || null,
      email: formData.email || null,
      username: formData.instagramUsername || null,
      brand_name: formData.brandName || null,
      company_name: formData.companyName || null,
      state: formData.state || null,
      city: formData.city || null,
      product_category: formData.productCategory || null,
      instagram_followers: formData.followers || null,
      // instagram_link: formData.instagramProfileLink || null,
      user_type: userType,
    }

    try {
      const response = await axios.post('https://laiza.encantotech.in/api/signup_web', payload)
      console.log('API Response:', response.data)
      // Toast.fire({
      //   icon: 'success',
      //   title: response.data.message || 'Form submitted successfully!',
      // })
      setOpenForm(false)
      setOpenSuccess(true)
      setFormData({
        fullName: '',
        brandName: '',
        companyName: '',
        email: '',
        phoneNo: '',
        city: '',
        state: '',
        country: '',
        productCategory: '',
        followers: '',
        // instagramProfileLink: '',
        instagramUsername: '',
      })

      setTimeout(() => {
        setOpenSuccess(false)
      }, 2000)
    } catch (error) {
      console.error('Error while submitting the form:', error)

      const errorMessage = error.response?.data?.message || 'Submission failed. Please try again.'

      Toast.fire({
        icon: 'error',
        title: errorMessage,
      })
    }
  }

  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }
  const styles = `
  .swal-toast-container {
    z-index: 9999 !important; / Ensure it's above the dialogs /
  }
`
  const styleSheet = document.createElement('style')
  styleSheet.type = 'text/css'
  styleSheet.innerText = styles
  document.head.appendChild(styleSheet)
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{ maxWidth: 500, marginBottom: '20px', gap: '10px' }}
      >
        <Button
          variant="outlined"
          onClick={() => handleRoleChange('seller')}
          className="pulsing-button"
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderColor: '#232323',
            fontFamily: 'Lato, sans-serif',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#232323',
            '&:hover': {
              backgroundColor: '#232323',
              color: '#ffffff',
              borderColor: '#232323',
              boxShadow: '0px 0px 5px rgba(121, 176, 196, 0.5)',
            },
            marginRight: '10px',
          }}
        >
          Join as a Seller!
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleRoleChange('influencer')}
          className="pulsing-button"
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderColor: '#232323',
            fontFamily: 'Lato, sans-serif',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#232323',
            '&:hover': {
              backgroundColor: '#232323',
              color: '#ffffff',
              borderColor: '#232323',
              boxShadow: '0px 0px 5px rgba(121, 176, 196, 0.5)',
            },
          }}
        >
          Join as an Influencer!
        </Button>
      </Box>

      <Dialog open={openForm}>
        <Typography
          variant="h6"
          align="center"
          sx={{
            paddingTop: '20px',
            fontFamily: 'Inter, sans-serif',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.78px',
            textAlign: 'center',
            color: '#626262',
          }}
        >
          {role === 'seller' ? 'Start Selling, Start Growing' : 'Influence with Purpose'}
        </Typography>

        {role === 'seller' ? (
          <SellerForm
            handleClose={handleCloseForm}
            handleSubmit={handleFormSubmit}
            formData={formData}
            handleInputChange={handleInputChange}
            countries={countries}
            states={states}
            cities={cities}
          />
        ) : (
          <InfluencerForm
            handleClose={handleCloseForm}
            handleSubmit={handleFormSubmit}
            formData={formData}
            handleInputChange={handleInputChange}
            countries={countries}
            states={states}
            cities={cities}
          />
        )}
      </Dialog>

      <Dialog open={openSuccess} fullWidth maxWidth="xs">
        <DialogContent className="successDialogContent">
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={imageSrc} alt="Success Icon" className="successIcon" />
            <Typography className="successMessage">
              Your details have been received successfully!
            </Typography>
            <Typography className="thankYouMessage">Thank You!!!</Typography>
            <Typography className="subText">We will be back to you once we are live!!</Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="outlined" onClick={handleCloseSuccess} className="closeButton">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default JoinUsForm
