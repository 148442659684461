import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Container,
} from '@mui/material'
import Swal from 'sweetalert2'
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
  customClass: {
    container: 'swal-toast-container',
    popup: 'swal-toast-popup',
  },
})
const Seller = () => {
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [formData, setFormData] = useState({
    fullName: '',
    brandName: '',
    companyName: '',
    email: '',
    phoneNo: '',
    city: '',
    state: '',
    country: '',
    productCategory: '',
    followers: '',
    // instagramProfileLink: '',
  })

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://laiza.encantotech.in/api/get_countries')
        setCountries(response.data.data)
      } catch (error) {
        console.error(error)
        Toast.fire({ icon: 'error', title: 'Error fetching countries.' })
      }
    }

    fetchCountries()
  }, [])

  const fetchStates = async () => {
    if (formData.country) {
      try {
        const response = await axios.get(
          `https://laiza.encantotech.in/api/get_states/${formData.country}`,
        )
        setStates(response.data.data)
      } catch (error) {
        console.error(error)
        Toast.fire({ icon: 'error', title: 'Error fetching states.' })
      }
    }
  }

  const fetchCities = async () => {
    if (formData.state) {
      try {
        const response = await axios.get(
          `https://laiza.encantotech.in/api/get_cities/${formData.state}`,
        )
        setCities(response.data.data)
      } catch (error) {
        console.error(error)
        Toast.fire({ icon: 'error', title: 'Error fetching cities.' })
      }
    }
  }

  useEffect(() => {
    fetchStates()
  }, [formData.country])

  useEffect(() => {
    fetchCities()
  }, [formData.state])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const payload = {
      name: formData.fullName || null,
      phone_number: formData.phoneNo || null,
      email: formData.email || null,
      username: formData.instagramUsername || null,
      brand_name: formData.brandName || null,
      company_name: formData.companyName || null,
      state: formData.state || null,
      city: formData.city || null,
      product_category: formData.productCategory || null,
      instagram_followers: formData.followers || null,
      // instagram_link: formData.instagramProfileLink || null,
      user_type: 'seller',
    }
    try {
      const response = await axios.post('https://laiza.encantotech.in/api/signup_web', payload)
      Toast.fire({
        icon: 'success',
        title: response.data.message || 'Form submitted successfully!',
      })
      setFormData({
        fullName: '',
        brandName: '',
        companyName: '',
        email: '',
        phoneNo: '',
        city: '',
        state: '',
        country: '',
        productCategory: '',
        followers: '',
        // instagramProfileLink: '',
      })
    } catch (error) {
      console.error('Error submitting form:', error)
      Toast.fire({ icon: 'error', title: 'Submission failed. Please try again.' })
    }
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#fff',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Join as a Seller
      </Typography>
      <form onSubmit={handleFormSubmit}>
        <TextField
          label="Full Name"
          fullWidth
          margin="normal"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          className="customTextField"
        />
        <TextField
          label="Brand Name"
          fullWidth
          margin="normal"
          name="brandName"
          value={formData.brandName}
          onChange={handleInputChange}
          className="customTextField"
        />
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          className="customTextField"
          name="instagramUsername"
          value={formData.instagramUsername}
          onChange={handleInputChange}
        />
        <TextField
          label="Company Name"
          fullWidth
          margin="normal"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          className="customTextField"
        />
        <TextField
          label="Email ID"
          fullWidth
          margin="normal"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="customTextField"
        />
        <TextField
          label="Phone No."
          fullWidth
          margin="normal"
          name="phoneNo"
          value={formData.phoneNo}
          onChange={handleInputChange}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9+]/g, '')
          }}
          className="customTextField"
        />
        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="country" value={formData.country} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select country</span>
            </MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="state" value={formData.state} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select state</span>
            </MenuItem>
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" className="customSelect">
          <Select name="city" value={formData.city} onChange={handleInputChange} displayEmpty>
            <MenuItem value="">
              <span style={{ color: '#828282' }}>Select city</span>
            </MenuItem>
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Product Category"
          fullWidth
          margin="normal"
          name="productCategory"
          value={formData.productCategory}
          onChange={handleInputChange}
          className="customTextField"
        />
        <TextField
          label="Followers on Instagram"
          fullWidth
          margin="normal"
          name="followers"
          value={formData.followers}
          onChange={handleInputChange}
          className="customTextField"
        />
        {/* <TextField
          label="Instagram Profile Link"
          fullWidth
          margin="normal"
          name="instagramProfileLink"
          value={formData.instagramProfileLink}
          onChange={handleInputChange}
          className="customTextField"
        /> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            className="customJoinButton"
            sx={{
              width: '50%',
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#115293',
              },
            }}
          >
            Join Now
          </Button>
        </Box>
      </form>
    </Container>
  )
}

export default Seller
