import React from 'react'
import { Box, Typography, IconButton, Link, Stack, Grid } from '@mui/material'
import { Facebook, Instagram, YouTube, Email, Phone } from '@mui/icons-material'
import XIcon from '@mui/icons-material/X'
const Footer = () => (
  <>
    <Box className="footer">
      <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} alignItems="center" sx={{ textAlign: 'center' }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Lato, sans-serif',
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.02em',
                textAlign: 'left',
              }}
            >
              Contact Us-
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato, sans-serif',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22.2px',
                letterSpacing: '0.02em',
                textAlign: 'left',
              }}
            >
              <Phone sx={{ marginRight: 1 }} /> +91 7208767229
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato, sans-serif',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22.2px',
                letterSpacing: '0.02em',
                textAlign: 'left',
              }}
            >
              <Email sx={{ marginRight: 1 }} />
              <a
                href="mailto:support@laiza.live"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                support@laiza.live
              </a>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Lato, sans-serif',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.02em',
              marginBottom: '20px',
            }}
          >
            Follow us on
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: {
                xs: 1,
                sm: 2,
                md: 4,
                lg: 8,
                xl: 8,
              },
            }}
          >
            <IconButton
              href="https://www.facebook.com/profile.php?id=61561530164278"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook sx={{ color: '#fff', width: '36px', height: '36px' }} />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/laizalive/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram sx={{ color: '#fff', width: '36px', height: '36px' }} />
            </IconButton>
            <IconButton href="https://x.com/LaizaLive" target="_blank" rel="noopener noreferrer">
              <XIcon sx={{ color: '#fff', width: '36px', height: '36px' }} />
            </IconButton>
            <IconButton
              href="https://www.youtube.com/@LaizaLive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTube sx={{ color: '#fff', width: '36px', height: '36px' }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
)

export default Footer
