import React from 'react'
import { Box, Typography, Grid, Button } from '@mui/material'
import Rectangle1 from '../assets/Rectangle1.png'
import Rectangle2 from '../assets/Rectangle2.png'
import Rectangle3 from '../assets/Rectangle3.png'
import Rectangle4 from '../assets/Rectangle4.png'
import Rectangle5 from '../assets/Rectangle5.png'

const LaizaImagePage = () => {
  return (
    <Box className="main-content">
      <Box textAlign="center" sx={{ maxWidth: '980px', margin: '0 auto' }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Nunito, sans-serif',
            fontSize: '52px',
            fontWeight: 700,
            lineHeight: '60px',
            textAlign: 'center',
            color: '#000000',
            marginBottom: '10px',
          }}
        >
          Join Us on Laiza – Become
          <br />
          an Influencer or Seller!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: 'Nunito, sans-serif',
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '28.8px',
            textAlign: 'center',
            color: '#798889',
            marginBottom: '20px',
          }}
        >
          Grow your brand, connect with an engaged audience, and sell in real-time. Whether you're
          an influencer looking to promote products or a seller seeking to collaborate with top
          creators, Laiza is your platform for success.
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <img src={Rectangle1} alt="Main Banner" loading="lazy"  style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <img src={Rectangle2} alt="Main Banner" loading="lazy"  style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src={Rectangle3} alt="Image1" loading="lazy"  style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src={Rectangle4} alt="Image2" loading="lazy"  style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src={Rectangle5} alt="Image3" loading="lazy"  style={{ width: '100%', borderRadius: '8px' }} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LaizaImagePage
