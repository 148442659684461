import React from 'react'
import { Typography, Grid, Card, CardContent, Avatar, Box } from '@mui/material'
import Frame1 from '../assets/Frame1.png'
import Frame2 from '../assets/Frame2.png'
import Frame3 from '../assets/Frame3.png'
import Frame4 from '../assets/Frame4.png'
import FaqsSection from './Faq'

const testimonials = [
  {
    name: 'Aanya',
    title: 'Home Decor Lover',
    feedback:
      'The variety of products is amazing. I can buy everything from beauty products to home appliances in one place!',
    avatar: Frame1,
  },
  {
    name: 'Kartikeya',
    title: 'Fashion Enthusiast',
    feedback:
      'I found the perfect dress for a party on Laiza! The live shopping feature gave me a clear idea of what to expect, and I’m thrilled with the quality!',
    avatar: Frame3,
  },
  {
    name: 'Emily White',
    title: 'Fashion Model & Social Media Influencer',
    feedback:
      'With Laiza, I can easily connect with sellers, watch live demos, and shop confidently knowing I’m getting quality products!',
    avatar: Frame2,
  },

  {
    name: 'Vihaan',
    title: 'Freelancer & Trend Seeker',
    feedback:
      'The convenience of Laiza’s live shopping made it easy for me to find the perfect gift. I could see the quality firsthand before buying!',
    avatar: Frame4,
  },
]

const Testimonials = () => {
  return (
    <Box className="testimonials">
      <Box sx={{ maxWidth: '650px', margin: '0 auto', textAlign: 'center' }}>
        {' '}
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontFamily: 'Nunito, sans-serif',
            fontSize: '48px',
            fontWeight: 700,
            lineHeight: '55px',
            color: '#000000',
          }}
        >
          What Our Users Are Saying
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontFamily: 'Nunito, sans-serif',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '30px',
            color: '#798889',
          }}
        >
          Join millions who have transformed their shopping experience with Laiza!
        </Typography>
      </Box>

      <Grid container spacing={1} sx={{ mt: 2, justifyContent: 'center', mb: 2 }}>
        {testimonials.map((testimonial, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Card
              variant="outlined"
              sx={{
                textAlign: 'center',
                p: 2,
                maxWidth: '300px',
                border: '1px solid #798889',
                borderRadius: '12px',
              }}
            >
              <Avatar
                src={testimonial.avatar}
                alt={testimonial.name}
                sx={{ width: 80, height: 80, margin: '0 auto 16px' }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '18px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#232323',
                  }}
                >
                  {testimonial.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    textAlign: 'center',
                    color: '#454545',
                  }}
                >
                  {testimonial.title}
                </Typography>
                <Typography
                  variant="body2"
                  mt={2}
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    textAlign: 'center',
                    color: '#696969',
                  }}
                >
                  {testimonial.feedback}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <FaqsSection />
    </Box>
  )
}

export default Testimonials
