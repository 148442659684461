import React, { useState } from 'react'
import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

const sellersFaqs = [
  {
    section: 'General Questions',
    items: [
      {
        question: 'What is Laiza.live?',
        answer:
          'Laiza.live is a live commerce platform where sellers can showcase their products through live streams or uploaded videos, allowing customers to interact, ask questions, and purchase in real-time.',
      },
      {
        question: 'How do I sign up as a seller on Laiza.live?',
        answer:
          'To sign up, go to the seller registration page, fill out your details, submit the necessary documents, and wait for approval from our team.',
      },
      {
        question: 'What are the benefits of selling on Laiza.live?',
        answer:
          'Laiza.live allows you to directly interact with buyers via live streaming, create a more engaging shopping experience, collaborate with influencers to boost sales, and access a growing community of online shoppers.',
      },
    ],
  },
  {
    section: 'Getting Started',
    items: [
      {
        question: 'What are the requirements to become a seller?',
        answer:
          'You need a registered business, a valid GST number (for India), and quality products to sell. You should also comply with our seller policies.',
      },
      {
        question: 'How can I set up my seller profile?',
        answer:
          'Once your account is approved, you can log in and set up your profile by adding a business logo, business information, contact details, and product categories.',
      },
      {
        question: 'What kind of products can I sell on Laiza.live?',
        answer:
          'You can sell a wide range of products, from fashion and accessories to electronics and home goods. However, products must comply with our quality and legal standards.',
      },
      {
        question: 'Are there any product restrictions?',
        answer:
          'Yes, items like prohibited goods (e.g., illegal products, hazardous materials) cannot be sold. You can check the full list of restricted items in our Seller Policies section.',
      },
    ],
  },
  {
    section: 'Live Streaming and Videos',
    items: [
      {
        question: 'How do I create a live stream to showcase my products?',
        answer:
          'After logging into your seller dashboard, click on the "Start Live Stream" button, select the products you want to feature, and go live. Ensure you have a good internet connection and a well-lit space for a high-quality stream.',
      },
      {
        question: 'Can I upload videos instead of going live?',
        answer:
          'Yes, you can upload pre-recorded product videos to your store for buyers to view. These videos will be displayed in your product catalog.',
      },
      {
        question: 'How can I promote my live stream?',
        answer:
          'You can promote your live stream by sharing the link on social media, through email campaigns, or by collaborating with influencers on Laiza.live.',
      },
      {
        question: 'Can I invite influencers to promote my products during live streams?',
        answer:
          'Yes, you can partner with influencers to co-host or promote your live streams. Influencers earn a commission on each sale they help generate.',
      },
    ],
  },
  {
    section: 'Payments and Fees',
    items: [
      {
        question: 'What are the fees for selling on Laiza.live?',
        answer:
          'Laiza.live charges a small commission on each sale. There are no setup fees or monthly charges. You can check the detailed fee structure in the Seller Dashboard under "Fees & Payments."',
      },
      {
        question: 'How and when do I get paid?',
        answer:
          'Payments are processed after the order has been confirmed and shipped. You will receive your earnings directly into your bank account within 7-10 business days after the transaction is completed.',
      },
      {
        question: 'Is there a minimum payout threshold?',
        answer:
          'Yes, the minimum payout threshold is [insert amount here], and any balance above this will be automatically transferred to your account.',
      },
    ],
  },
  {
    section: 'Orders and Shipping',
    items: [
      {
        question: 'How do I manage my orders?',
        answer:
          'You can manage your orders through the Seller Dashboard. Once an order is placed, you will be notified and can view the details, print shipping labels, and mark the order as shipped once dispatched.',
      },
      {
        question: 'Who handles shipping?',
        answer:
          'You can either manage shipping on your own or use our partnered logistics providers. If you use our integrated logistics, we will handle shipping on your behalf.',
      },
      {
        question: 'Can I set my own shipping rates?',
        answer:
          'Yes, you can set your own shipping rates or offer free shipping. You also have the option to use our standard shipping rates if you prefer.',
      },
      {
        question: 'How do I track shipped orders?',
        answer:
          'Once an order is shipped, tracking information will be automatically provided in the Seller Dashboard and shared with the buyer.',
      },
      {
        question: 'What if an order is canceled by the buyer?',
        answer:
          'If an order is canceled before it’s shipped, you will be notified and no payment will be processed. If an order is canceled after shipping, the return and refund policy will apply.',
      },
    ],
  },
  {
    section: 'Returns and Refunds',
    items: [
      {
        question: 'What is Laiza.live’s return policy for sellers?',
        answer:
          "You can set your own return policy, but it must comply with Laiza.live's guidelines. The return policy should be clear to customers during the purchase process.",
      },
      {
        question: 'Who is responsible for handling product returns?',
        answer:
          'As the seller, you are responsible for managing product returns based on the return policy. You can arrange for the product to be shipped back to you or use our returns service for assistance.',
      },
      {
        question: 'What happens if a buyer requests a refund?',
        answer:
          'If a buyer requests a refund, we will notify you. Refunds are processed based on the reason for the request and your return/refund policy.',
      },
    ],
  },
  {
    section: 'Seller Performance and Support',
    items: [
      {
        question: 'How do I improve my seller performance?',
        answer:
          'Regularly update your products, offer timely shipping, maintain a good return policy, and ensure high-quality customer service. Positive ratings from buyers also boost your visibility.',
      },
      {
        question: 'Can I get feedback from buyers?',
        answer:
          'Yes, buyers can leave ratings and reviews on your products and live streams. You can view these in the Seller Dashboard and respond to feedback.',
      },
      {
        question: 'What happens if I receive negative reviews?',
        answer:
          'Negative reviews can impact your seller rating. Address any complaints professionally and work to resolve issues quickly. If a review is unjustified, you can report it to our support team for further investigation.',
      },
      {
        question: 'How can I contact Laiza.live seller support?',
        answer:
          'You can reach out to our seller support team via email, live chat, or through the support section in the Seller Dashboard.',
      },
    ],
  },
  {
    section: 'Product Listings and Inventory',
    items: [
      {
        question: 'How do I add products to my store?',
        answer:
          'You can add products from your Seller Dashboard by uploading images, descriptions, prices, and other product details. Ensure all product information is accurate and up to date.',
      },
      {
        question: 'Can I list products in multiple categories?',
        answer:
          'Yes, you can list products in multiple relevant categories to increase visibility. Make sure the products fit within the selected categories to avoid confusion.',
      },
      {
        question: 'How do I manage my inventory?',
        answer:
          'You can manage your inventory from the Seller Dashboard. Keep track of stock levels, and update product availability to avoid overselling.',
      },
      {
        question: 'Can I offer discounts on my products?',
        answer:
          'Yes, you can offer discounts, run promotions, or provide special offers through the promotions section in your Seller Dashboard.',
      },
      {
        question: 'What if I run out of stock for a product?',
        answer:
          "You can temporarily hide the product from your store or mark it as 'out of stock' until new stock is available.",
      },
    ],
  },
  {
    section: 'Account Management',
    items: [
      {
        question: 'How do I update my business information?',
        answer:
          'You can update your business information, such as your address, contact details, and banking information, in the account settings of your Seller Dashboard.',
      },
      {
        question: 'What should I do if I forget my password?',
        answer:
          "If you forget your password, click on the 'Forgot Password' link on the login page, and follow the steps to reset it.",
      },
      {
        question: 'How do I close my seller account?',
        answer:
          'To close your seller account, contact our support team through the dashboard or email. Please note that you must complete any pending orders and resolve all disputes before closing your account.',
      },
      {
        question: 'Can I have multiple seller accounts?',
        answer:
          'No, each business is allowed only one seller account. Multiple accounts are not permitted as they can affect the platform’s integrity.',
      },
      {
        question: 'How do I verify my identity as a seller?',
        answer:
          'Identity verification is part of the seller registration process. You may need to provide documents such as your business license, GST registration (for India), and identification proof.',
      },
    ],
  },
  {
    section: 'Advanced Features and Tools',
    items: [
      {
        question: 'How can I use analytics to improve my sales?',
        answer:
          'Laiza.live provides detailed sales analytics in your Seller Dashboard, including views, live stream engagement, conversion rates, and customer demographics. Use these insights to optimize your product listings and live streams.',
      },
      {
        question: 'Can I schedule my live streams in advance?',
        answer:
          'Yes, you can schedule your live streams in advance. This allows you to promote the event beforehand and attract a larger audience. The schedule feature is available in the live stream settings.',
      },
      {
        question: 'How do I use the promotion tools on Laiza.live?',
        answer:
          'You can access promotion tools like featured listings, discounts, and flash sales from your Seller Dashboard. These tools help boost product visibility and increase sales during peak periods.',
      },
      {
        question: 'Can I offer gift cards or coupons?',
        answer:
          'Yes, you can create and offer digital gift cards or discount coupons for your customers. These can be customized in your promotions settings, allowing you to attract more buyers.',
      },
      {
        question: 'Does Laiza.live offer advertising options for sellers?',
        answer:
          'Yes, you can advertise your products through paid promotion options such as featured product placements, banner ads, or targeted campaigns within Laiza.live.',
      },
      {
        question: "What is the 'Influencer Collaboration' feature?",
        answer:
          'The Influencer Collaboration feature allows sellers to partner with influencers to promote their products. You can invite influencers to host live streams or showcase your products in exchange for a commission.',
      },
      {
        question: "How can I use 'bundling' to boost sales?",
        answer:
          'You can create product bundles by grouping related products and offering them at a discounted price. Bundles encourage customers to purchase more items in a single transaction.',
      },
    ],
  },
  {
    section: 'Compliance and Legal',
    items: [
      {
        question: 'Do I need to submit any legal documents to sell on Laiza.live?',
        answer:
          'Yes, you must submit a copy of your business registration, GST number, and other required tax documents during the onboarding process to comply with legal regulations.',
      },
      {
        question: "What are Laiza.live's compliance policies for sellers?",
        answer:
          'Sellers are required to comply with Laiza.live’s policies, which include selling authentic products, providing accurate product descriptions, following fair trade practices, and adhering to tax and legal obligations.',
      },
      {
        question: 'How does Laiza.live ensure product authenticity?',
        answer:
          'Sellers must provide accurate product descriptions and documentation to verify product authenticity. Selling counterfeit or illegal products is strictly prohibited, and violators may be banned from the platform.',
      },
      {
        question: 'What happens if I violate the seller policies?',
        answer:
          'Violating seller policies may result in warnings, suspension, or termination of your seller account, depending on the severity of the violation. Repeat offenses could lead to permanent removal from the platform.',
      },
      {
        question: 'How can I report a dispute with a buyer?',
        answer:
          'If a dispute arises, you can open a case through the dispute resolution system in your Seller Dashboard. Provide all necessary documentation, and our team will mediate to resolve the issue.',
      },
      {
        question: 'Can I appeal if my seller account is suspended?',
        answer:
          'Yes, if your account is suspended, you can submit an appeal by contacting Laiza.live support. Provide detailed information about your case, and our team will review the suspension.',
      },
      {
        question: 'How do I handle tax-related queries or issues?',
        answer:
          'Laiza.live provides basic tax guidelines, but we recommend consulting a tax professional for specific tax-related concerns. You can also access your transaction history and tax invoices from the Seller Dashboard.',
      },
    ],
  },
  {
    section: 'Technical and Platform Support',
    items: [
      {
        question: 'What should I do if I experience technical issues during a live stream?',
        answer:
          'If you encounter technical issues during a live stream, check your internet connection first. You can also contact Laiza.live’s technical support for real-time assistance through the support chat in your dashboard.',
      },
      {
        question: 'Is there a mobile app for sellers?',
        answer:
          'Yes, Laiza.live offers a mobile app for sellers. You can manage your store, live streams, and orders directly from your smartphone, allowing you to stay connected on the go.',
      },
      {
        question: 'How do I update my store settings?',
        answer:
          "You can update your store settings, such as product categories, business hours, shipping preferences, and return policies, from the Seller Dashboard under 'Store Settings.'",
      },
      {
        question: 'What if I encounter a platform bug or error?',
        answer:
          "If you discover a bug or error, report it through the 'Report an Issue' option in your dashboard or mobile app. Our technical team will work to resolve the issue as quickly as possible.",
      },
    ],
  },
  {
    section: 'Customer Service and Communication',
    items: [
      {
        question: 'How can I improve communication with my customers?',
        answer:
          'You can use Laiza.live’s messaging feature to communicate directly with buyers. Responding promptly to questions and concerns can improve customer satisfaction and increase sales.',
      },
      {
        question: 'Can I create automated responses for customer queries?',
        answer:
          'Yes, you can set up automated responses for frequently asked questions (FAQs) in your messaging system. This can help you manage customer inquiries efficiently.',
      },
      {
        question: 'How do I handle customer complaints?',
        answer:
          'Address complaints quickly by communicating professionally with the customer through the messaging system. If the issue escalates, you can involve Laiza.live’s support team for mediation.',
      },
      {
        question: 'Can I offer customer support outside of Laiza.live?',
        answer:
          'Yes, you can provide additional customer support through social media, email, or your website, but all order-related communication should be documented within the Laiza.live platform.',
      },
      {
        question: 'How do I increase customer loyalty?',
        answer:
          'Offer excellent customer service, run promotions, provide exclusive offers, and consider implementing a loyalty program for repeat buyers to encourage long-term customer relationships.',
      },
    ],
  },
  {
    section: 'Product Quality and Listings',
    items: [
      {
        question: 'How can I improve the quality of my product listings?',
        answer:
          'Use high-quality images, detailed and accurate product descriptions, and include all relevant specifications and features. This will help attract more customers and reduce returns.',
      },
      {
        question: 'What if I want to temporarily remove a product from my store?',
        answer:
          'You can temporarily deactivate or hide a product listing if it is out of stock or undergoing updates. Simply toggle the product visibility option in your Seller Dashboard.',
      },
      {
        question: 'Can I offer customizations on my products?',
        answer:
          'Yes, if your products allow customization, you can offer buyers the option to personalize their orders. Simply specify the available customizations in the product description.',
      },
    ],
  },
  {
    section: 'Security and Privacy',
    items: [
      {
        question: 'How does Laiza.live ensure my business information is secure?',
        answer:
          'Laiza.live uses encryption and other security measures to protect your personal and business information. We do not share your details with third parties without your consent.',
      },
      {
        question: 'What should I do if I suspect fraudulent activity on my account?',
        answer:
          'If you suspect unauthorized access or fraudulent activity on your account, immediately change your password and contact Laiza.live support for further investigation.',
      },
      {
        question: 'How can I protect my account from being hacked?',
        answer:
          'Use a strong password, enable two-factor authentication, and regularly monitor your account activity. Avoid sharing login details with unauthorized persons.',
      },
    ],
  },
]

const influencersFaqs = [
  {
    section: 'Getting Started',
    items: [
      {
        question: 'What is Laiza.live for influencers?',
        answer:
          'Laiza.live allows influencers to collaborate with sellers to promote products through live streaming and video content. You earn commissions on each sale generated from your streams and videos.',
      },
      {
        question: 'How do I sign up as an influencer on Laiza.live?',
        answer:
          "To sign up, visit the Laiza.live website or download the mobile app. Select the 'Influencer' option during registration, fill out your details, and complete the verification process.",
      },
      {
        question: 'What are the requirements to become an influencer on Laiza.live?',
        answer:
          'You need a verified social media account, a strong follower base, and consistent engagement on your content. You’ll also need to provide a valid ID and bank details for commission payments.',
      },
      {
        question: 'Can I be both a seller and an influencer on Laiza.live?',
        answer:
          'Yes, you can operate both as a seller and an influencer. You will have access to both dashboards, allowing you to manage product sales and promotions seamlessly.',
      },
      {
        question: 'Is there a fee to become an influencer on Laiza.live?',
        answer:
          'No, there is no fee to join as an influencer. You only earn commissions based on the sales generated through your live streams and promotional videos.',
      },
    ],
  },
  {
    section: 'Collaborating with Sellers',
    items: [
      {
        question: 'How do I find products to promote?',
        answer:
          "Browse through the 'Influencer Marketplace' on Laiza.live, where sellers list products available for promotion. You can request to collaborate with sellers or accept offers from them.",
      },
      {
        question: 'How do I collaborate with a seller?',
        answer:
          "Once you find a product you like, click the 'Request Collaboration' button on the product page. If the seller approves your request, you can start promoting their product.",
      },
      {
        question: 'Can I collaborate with multiple sellers at once?',
        answer:
          'Yes, you can collaborate with multiple sellers at the same time. However, it’s important to manage your collaborations carefully to avoid conflicts and maintain the quality of your promotions.',
      },
      {
        question: 'How do I know which products are available for promotion?',
        answer:
          "Available products will be listed in the 'Available for Promotion' section of your dashboard. Sellers will also send collaboration requests directly to you if they want you to promote their products.",
      },
      {
        question: 'Can I decline a seller’s collaboration request?',
        answer:
          'Yes, you have the option to decline any collaboration request that doesn’t align with your brand or content strategy.',
      },
    ],
  },
  {
    section: 'Live Streaming and Content Creation',
    items: [
      {
        question: 'How do I start a live stream on Laiza.live?',
        answer:
          "To start a live stream, go to your Influencer Dashboard, select the product you want to promote, and click 'Go Live.' You can add product details, interact with viewers, and highlight features during the stream.",
      },
      {
        question: 'Can I schedule my live streams in advance?',
        answer:
          'Yes, you can schedule live streams in advance, allowing you to promote the event on social media and attract a larger audience.',
      },
      {
        question: 'What kind of content should I create to promote products?',
        answer:
          'Focus on creating engaging content that showcases the product’s features, provides honest reviews, and interacts with your audience. You can use live streams, pre-recorded videos, and social media posts.',
      },
      {
        question: 'Are there any guidelines for promoting products?',
        answer:
          'Yes, Laiza.live requires influencers to follow ethical practices, including honest reviews, full disclosure of sponsored content, and adherence to the platform’s community guidelines.',
      },
      {
        question: 'Can I create pre-recorded videos instead of live streams?',
        answer:
          'Yes, you can create and upload pre-recorded videos promoting a seller’s products. However, live streams tend to generate more engagement and higher sales.',
      },
      {
        question: 'What tools are available for content creation on Laiza.live?',
        answer:
          'Laiza.live offers built-in tools for live streaming, video editing, product showcasing, and viewer interaction. You can also access analytics to track your performance.',
      },
    ],
  },
  {
    section: 'Commissions and Payments',
    items: [
      {
        question: 'How do I earn commissions on Laiza.live?',
        answer:
          'You earn a commission on each sale made through your live stream or video. The commission rate is agreed upon between you and the seller during collaboration.',
      },
      {
        question: 'How are commissions calculated?',
        answer:
          'Commissions are calculated as a percentage of the sale amount, which is agreed upon when you collaborate with a seller. You can track your earnings in the Influencer Dashboard.',
      },
      {
        question: 'When will I receive my commission payments?',
        answer:
          'Commissions are paid out after the buyer’s return period ends, usually within 7-14 days. Payments are made directly to your bank account.',
      },
      {
        question: 'Where can I see my commission earnings?',
        answer:
          "You can view your total earnings, pending commissions, and payment history in the 'Earnings' section of your Influencer Dashboard.",
      },
      {
        question: 'What happens if a product is returned by the buyer?',
        answer:
          'If a product is returned by the buyer, the commission for that sale will be voided, and the amount will be deducted from your pending or future earnings.',
      },
      {
        question: 'Can I negotiate the commission rate with sellers?',
        answer:
          'Yes, you can negotiate the commission rate directly with the seller before agreeing to a collaboration. Make sure both parties agree on the terms before promoting the product.',
      },
    ],
  },
  {
    section: 'Performance and Analytics',
    items: [
      {
        question: 'How do I track the performance of my live streams?',
        answer:
          'You can access detailed analytics in your Influencer Dashboard, including viewer engagement, sales conversions, and product performance.',
      },
      {
        question: 'What metrics are available for tracking?',
        answer:
          'Metrics include total views, average watch time, sales conversions, click-through rates, and engagement levels (likes, comments, shares).',
      },
      {
        question: 'Can I improve my commission earnings?',
        answer:
          'Yes, by optimizing your content, engaging more with your audience, promoting high-demand products, and negotiating better commission rates with sellers.',
      },
      {
        question: 'How do I know which products are performing well?',
        answer:
          'The performance of each product is tracked through your Influencer Dashboard. You can see which products are generating the most sales and engagement.',
      },
    ],
  },
  {
    section: 'Support and Troubleshooting',
    items: [
      {
        question: 'What if I face technical issues during a live stream?',
        answer:
          'If you encounter technical difficulties, you can pause the stream and contact Laiza.live’s technical support team for assistance. Ensure your internet connection is stable before starting the stream.',
      },
      {
        question: 'How do I contact Laiza.live support?',
        answer:
          "You can reach Laiza.live’s support team through the 'Help' section in your dashboard or app. We offer real-time chat support as well as email assistance.",
      },
      {
        question: 'What should I do if a seller doesn’t pay my commission?',
        answer:
          'If you encounter payment issues, report the problem to Laiza.live’s support team. We will investigate and mediate the issue to ensure you receive your due commission.',
      },
      {
        question: 'Can I change my bank details for commission payments?',
        answer:
          "Yes, you can update your payment information in your account settings under the 'Payments' section.",
      },
    ],
  },
  {
    section: 'Compliance and Legal',
    items: [
      {
        question: 'Do I need to disclose my collaborations with sellers?',
        answer:
          'Yes, influencers are required to disclose their paid collaborations as per Laiza.live’s guidelines and legal regulations. This helps maintain transparency with your audience.',
      },
      {
        question: 'Can I promote any type of product?',
        answer:
          'You can promote any product that complies with Laiza.live’s terms and conditions. However, illegal, unethical, or harmful products are prohibited from the platform.',
      },
      {
        question: 'What happens if I violate Laiza.live’s policies?',
        answer:
          'Violating the platform’s policies may result in warnings, suspension, or permanent account termination, depending on the severity of the violation.',
      },
      {
        question: 'Is there a contract between influencers and sellers?',
        answer:
          'Yes, when you accept a collaboration with a seller, you agree to the terms of the contract, which includes commission rates, content expectations, and compliance with platform policies.',
      },
      {
        question: 'How does Laiza.live protect my personal information?',
        answer:
          'Laiza.live uses encryption and other security measures to safeguard your personal and financial information. We do not share your data with third parties without your consent.',
      },
    ],
  },
  {
    section: 'Growth and Opportunities',
    items: [
      {
        question: 'Can I receive brand sponsorships through Laiza.live?',
        answer:
          'Yes, sellers may offer sponsorship deals for long-term collaborations. Laiza.live also provides opportunities for top influencers to connect with brands directly.',
      },
      {
        question: 'How do I grow my follower base on Laiza.live?',
        answer:
          'Consistent engagement, high-quality content, and active promotion of your live streams and videos across social media channels can help grow your follower base.',
      },
      {
        question: 'Can I offer exclusive deals to my followers?',
        answer:
          'Yes, you can collaborate with sellers to offer exclusive discounts or promotions to your followers, increasing engagement and sales.',
      },
      {
        question: 'What is the Laiza.live Influencer Program?',
        answer:
          'The Laiza.live Influencer Program offers exclusive benefits to top-performing influencers, including higher commission rates, early access to products, and sponsorship opportunities.',
      },
      {
        question: 'Can I create my own brand/store as an influencer?',
        answer:
          'Yes, if you want to transition into selling products yourself, Laiza.live allows influencers to set up their own stores and manage product listings.',
      },
      {
        question: 'Can I collaborate with other influencers on Laiza.live?',
        answer:
          'Yes, Laiza.live encourages influencer collaboration. You can co-host live streams with other influencers or create joint promotional content, increasing reach and engagement.',
      },
      {
        question: 'How do I receive feedback from sellers about my promotion?',
        answer:
          "Sellers can provide feedback directly through the platform after a collaboration ends. You can view their comments and ratings in the 'Collaboration History' section of your dashboard.",
      },
      {
        question: 'Is there a limit to how many products I can promote at once?',
        answer:
          'There is no set limit on the number of products you can promote, but it’s recommended to maintain a balance so you can provide quality content for each product.',
      },
      {
        question: 'Can I use external tools for live streaming on Laiza.live?',
        answer:
          'Currently, Laiza.live offers in-built streaming tools, but you may use third-party tools for editing or enhancing your streams as long as the live broadcast happens through the platform.',
      },
      {
        question: 'How do I maintain long-term relationships with sellers?',
        answer:
          'Building trust by delivering quality promotions, meeting deadlines, and engaging audiences can help you establish long-term partnerships with sellers. Regular communication also helps.',
      },
      {
        question: 'Are there promotional incentives for top influencers?',
        answer:
          'Yes, Laiza.live offers incentives such as higher commission rates, exclusive product deals, and the ability to participate in featured campaigns for top-performing influencers.',
      },
      {
        question: 'Can I access Laiza.live’s platform insights to find high-demand products?',
        answer:
          'Yes, influencers can access platform-wide insights on trending products and categories, allowing you to focus on items that have a higher chance of selling well.',
      },
      {
        question: 'What happens if a seller provides false information about a product?',
        answer:
          'If you believe a seller has provided misleading information, you can report the issue to Laiza.live’s support team. The platform will investigate and take action if necessary.',
      },
      {
        question: 'How do I handle negative feedback from viewers or buyers?',
        answer:
          'Respond professionally to any negative feedback and address concerns where possible. Maintaining transparency with your audience is important for credibility.',
      },
      {
        question: 'Can I view analytics on my audience demographics?',
        answer:
          'Yes, Laiza.live provides demographic analytics (age, gender, location) for your viewers, helping you better understand and target your audience.',
      },
      {
        question: 'How can I improve engagement during my live streams?',
        answer:
          'Using interactive elements such as Q&A sessions, polls, giveaways, and responding to viewer comments can help increase engagement during live streams.',
      },
      {
        question: 'Can I promote my live streams on external social media platforms?',
        answer:
          'Yes, you can share your upcoming live streams and videos across your social media channels to attract more viewers and increase sales potential.',
      },
      {
        question: 'Are there any platform-specific certifications or training for influencers?',
        answer:
          'Laiza.live plans to introduce training programs and certifications for influencers to enhance their skills and increase their effectiveness in product promotion.',
      },
      {
        question: 'How do I resolve disputes with sellers?',
        answer:
          'If you encounter issues with a seller, you can use the dispute resolution feature available on the platform. Laiza.live’s team will mediate to help resolve the matter.',
      },
      {
        question: 'What happens if my account is suspended?',
        answer:
          'If your account is suspended due to a violation of platform rules, you will receive an email outlining the reasons. You can appeal the suspension by following the instructions provided.',
      },
      {
        question: 'How do I manage my content rights for the videos and live streams I create?',
        answer:
          'As an influencer, you retain the rights to the content you create, but Laiza.live may have the right to use your content for promotional purposes as per the platform’s terms of use.',
      },
      {
        question: 'Can I set my own schedule for live streams?',
        answer:
          'Yes, you have full control over your streaming schedule. However, collaborating with sellers to find the best timing for their target audience can maximize your reach.',
      },
      {
        question: 'Is there a way to cross-promote multiple products in a single stream?',
        answer:
          'Yes, you can feature multiple products from different sellers in a single live stream, provided you have collaborations with each seller and disclose all partnerships clearly.',
      },
      {
        question: 'Are there any seasonal promotions or events for influencers?',
        answer:
          'Laiza.live organizes special events, seasonal campaigns, and product launches where influencers can participate for increased visibility and higher sales opportunities.',
      },
      {
        question: 'What happens if I lose my audience during a live stream?',
        answer:
          'If technical issues or low engagement cause you to lose viewers, you can schedule another stream or reach out to Laiza.live’s support team to troubleshoot the issue.',
      },
    ],
  },
]

const FaqsSection = () => {
  const [expandedSellers, setExpandedSellers] = useState(false)
  const [expandedInfluencers, setExpandedInfluencers] = useState(false)

  const handleSellersChange = (panel) => (event, isExpanded) => {
    setExpandedSellers(isExpanded ? panel : false)
  }

  const handleInfluencersChange = (panel) => (event, isExpanded) => {
    setExpandedInfluencers(isExpanded ? panel : false)
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: { xs: '1rem', md: '2rem' },
        width: '100%',
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Nunito',
          fontSize: '36px',
          fontWeight: 600,
          lineHeight: '38px',
          color: '#232323',
        }}
      >
        Frequently Asked Questions
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Seller FAQs
          </Typography>
          <Box
            sx={{
              maxHeight: '450px',
              overflowY: 'auto',
              border: '1px solid #ccc',
              borderRadius: '10px',
              padding: '16px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f0f0f0',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#696969 !important',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#696969 !important',
              },
            }}
          >
            {sellersFaqs.map((faqGroup) => (
              <Box key={faqGroup.section} mb={2}>
                <Typography variant="h6">{faqGroup.section}</Typography>
                {faqGroup.items.map((faq, index) => (
                  <Accordion
                    key={index}
                    expanded={expandedSellers === `panel${faqGroup.section}-${index}`}
                    onChange={handleSellersChange(`panel${faqGroup.section}-${index}`)}
                  >
                    <AccordionSummary>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'Nunito',
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '27.28px',
                          color: '#282828',
                          textAlign: 'left',
                        }}
                      >
                        {faq.question}
                      </Typography>
                      {expandedSellers === `panel${faqGroup.section}-${index}` ? (
                        <CloseIcon sx={{ marginLeft: 'auto' }} />
                      ) : (
                        <AddIcon sx={{ marginLeft: 'auto' }} />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: 'Nunito',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '25.2px',
                          textAlign: 'left',
                          color: '#696969',
                        }}
                      >
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Divider />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" textAlign="center" gutterBottom>
            Influencer FAQs
          </Typography>
          <Box
            sx={{
              maxHeight: '450px',
              overflowY: 'auto',
              border: '1px solid #ccc',
              borderRadius: '10px',
              padding: '16px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f0f0f0',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#696969 !important',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#696969 !important',
              },
            }}
          >
            {influencersFaqs.map((faqGroup) => (
              <Box key={faqGroup.section} mb={2}>
                <Typography variant="h6">{faqGroup.section}</Typography>
                {faqGroup.items.map((faq, index) => (
                  <Accordion
                    key={index}
                    expanded={expandedInfluencers === `panel${faqGroup.section}-${index}`}
                    onChange={handleInfluencersChange(`panel${faqGroup.section}-${index}`)}
                  >
                    <AccordionSummary>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'Nunito',
                          fontSize: '16px',
                          fontWeight: 500,
                          lineHeight: '27.28px',
                          color: '#282828',
                          textAlign: 'left',
                        }}
                      >
                        {faq.question}
                      </Typography>
                      {expandedInfluencers === `panel${faqGroup.section}-${index}` ? (
                        <CloseIcon sx={{ marginLeft: 'auto' }} />
                      ) : (
                        <AddIcon sx={{ marginLeft: 'auto' }} />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: 'Nunito',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '25.2px',
                          textAlign: 'left',
                          color: '#696969',
                        }}
                      >
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Divider />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FaqsSection
