import React from 'react'
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
} from '@mui/material'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import CategoryIcon from '@mui/icons-material/Category'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import ChatIcon from '@mui/icons-material/Chat'
import SecurityIcon from '@mui/icons-material/Security'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import StoreIcon from '@mui/icons-material/Store'
import './style.css'
import laizafullimg from '../assets/InfluencerProfile.svg'
import imageSrc from '../assets/Vectorhad.png'
const listItems = [
  {
    icon: <LiveTvIcon />,
    primary: 'Live Shopping Feature',
    secondary: 'Shop in real-time during live sessions hosted by brands and influencers.',
  },
  {
    icon: <CategoryIcon />,
    primary: 'Diverse Product Categories',
    secondary:
      'From fashion and electronics to home decor and beauty, Laiza covers all your needs.',
  },
  {
    icon: <ChatIcon />,
    primary: 'Influencer Engagement',
    secondary:
      'Follow your favorite influencers, watch their product reviews, and shop directly from their recommendations.',
  },
  {
    icon: <LocalOfferIcon />,
    primary: 'Exclusive Deals & Offers',
    secondary: 'Unlock time-limited deals and discounts during live streams.',
  },

  {
    icon: <SecurityIcon />,
    primary: 'Personalized Shopping',
    secondary: 'Get recommendations based on your preferences and browsing history.',
  },
  {
    icon: <AccessTimeIcon />,
    primary: 'Instant Checkout',
    secondary: 'Fast and secure checkout process, reducing friction for users.',
  },
  {
    icon: <StoreIcon />,
    primary: 'Seamless Shopping Experience',
    secondary: 'Get recommendations based on your preferences and browsing history.',
  },
]
const FeaturesSection = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item xs={12} md={8} style={{ maxWidth: '800px', margin: '0 auto' }}>
          <Box
            mb={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Typography variant="h4" gutterBottom className="future-ecommerce-revolution-title">
              Revolutionizing the
            </Typography>
            <Typography variant="h4" gutterBottom className="future-ecommerce-revolution-title">
              E-Commerce Experience
            </Typography>
            <Typography variant="body1" gutterBottom className="future-ecommerce-description">
              A dynamic platform for live shopping, personalized recommendations, and real-time
              customer engagement.
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: 'center' }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: 'Nunito, sans-serif',
                  fontSize: '36px',
                  fontWeight: 600,
                  lineHeight: '57.6px',
                }}
              >
                Key Features & Benefits
              </Typography>
              <img
                src={imageSrc}
                alt="Description"
                loading="lazy"
                style={{ width: '35px', height: '70px', marginLeft: '10px' }}
              />
            </Box>
            <List>
              {listItems.map(({ icon, primary, secondary }, index) => (
                <ListItem key={index}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    primary={<span className="primary-text">{primary}</span>}
                    secondary={<span className="secondary-text">{secondary}</span>}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <img
            src={laizafullimg}
            alt="E-Commerce Features"
            loading="lazy"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '900px',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Stack sx={{ maxWidth: '1400px' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: 'Nunito, sans-serif',
                fontSize: '48px',
                fontWeight: 600,
                lineHeight: '57.6px',
                color: '#232323',
              }}
            >
              About Laiza
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontFamily: 'Nunito, sans-serif',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '30px',
                color: '#798889',
              }}
            >
              Laiza is a cutting-edge live e-commerce platform that not only connects users with top
              brands and influencers but also empowers influencers and sellers to collaborate and
              grow. With real-time shopping events, influencer-driven product recommendations, and a
              wide variety of categories, Laiza provides a seamless, interactive shopping
              experience. Influencers can join to showcase their favorite products, engage with
              their audience through live streams, and promote items from trusted brands. Sellers
              can leverage the platform to connect with influencers, boosting their visibility and
              sales through authentic, personalized promotions. Whether you're shopping for
              exclusive deals, growing your influence, or promoting your brand, Laiza offers endless
              possibilities in the dynamic world of live e-commerce.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FeaturesSection
